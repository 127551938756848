import { Injectable, inject } from "@angular/core";
import { IUserInfo, IUserInfoInStorage } from "./models/authentication.interface";
import { CoreAuthConfigService } from "@features/auth/core-auth-config.service";
import { StorageHelper } from "@features/auth/shared/helpers/storage.helper";
import { ILoginUserData } from "./dto/login-response.interface";
import { UserInfo } from "./models/version-1/auth-model-version1";
import { AuthService } from "./auth-version-2.service";
import { AuthVersion1Service } from "./auth-version-1.service";

@Injectable()
export class UserInfoStorageService implements IUserInfo {
  conf = inject(CoreAuthConfigService);
  authV2Service = inject(AuthService);
  authV1Service = inject(AuthVersion1Service);
  
  getInfoFromStorage(): IUserInfoInStorage {

    let userInfoStorage: ILoginUserData | UserInfo = StorageHelper.getUserInfo();

    if (this.conf.isAuthV2()) {
      userInfoStorage = userInfoStorage as ILoginUserData
      return {
        avatar: userInfoStorage?.avatar,
        displayName: userInfoStorage?.ho_ten,
        email: userInfoStorage?.email
      }
    }
    userInfoStorage = userInfoStorage as UserInfo;

    return {
      avatar: userInfoStorage?.image,
      displayName: userInfoStorage?.Ho_Ten,
      email: userInfoStorage?.email
    }
  }
  getAuthHeader() {
    if (this.conf.isAuthV1()) {
      return {
        Authorization: this.authV1Service.buildTokenByJWTPrefix(this.conf.applicationConfig.tokenType ?? ''),
        ['app-key']: this.conf.applicationConfig.appKey ?? ''
      }
    }
    return {
      Authorization: this.authV2Service.buildTokenByJWTPrefix(this.conf.applicationConfig.tokenType ?? ''),
      ['app-key']: this.conf.applicationConfig.appKey ?? ''
    }
  }
}